import { IReport } from '@bridebook/models/source/models/Suppliers/Reports.types';
import { IReportBasicData, ROIUserInput, ROIUserInputType } from 'lib/roi/types';

export enum ROIAnalyticsActions {
  ClickedToSetUpROICalculator = 'Clicked to set up ROI Calculator on CMS',
  ClickedToGenerateROIReport = 'Clicked to generate ROI report on CMS',
  GeneratedROIReport = 'Generated ROI report on CMS',
  DownloadedROIReport = 'Downloaded ROI report on CMS',
  EditedROIReport = 'Edited ROI report on CMS',
  RevealedLatestROIReport = 'Revealed latest ROI report on CMS',
  ViewedROIReport = `Viewed ROI report on CMS`,
}

export const clickedToSetUpROICalculatorAnalytics = (lastTierUpdateDate?: string) => ({
  type: ROIAnalyticsActions.ClickedToSetUpROICalculator,
  payload: lastTierUpdateDate,
});

export interface IClickedToGenerateROIReportAnalytics {
  avgRevenue: ROIUserInput['avgRevenueValue'] | ROIUserInput['avgRevenueSliderValue'];
  avgRevenueType: ROIUserInputType;
  grossProfitMargin:
    | ROIUserInput['grossProfitMarginValue']
    | ROIUserInput['grossProfitMarginRadioValue'];
  grossProfitMarginType: ROIUserInputType;
  enqToBooking: ROIUserInput['enquiryToBookingValue'] | ROIUserInput['enquiryToBookingSliderValue'];
  enqToBookingType: ROIUserInputType;
  reportInputType: 'modified' | 'newSetUp';
  lastTierUpdateDate?: string;
}
export const clickedToGenerateROIReportAnalytics = (
  payload: IClickedToGenerateROIReportAnalytics,
) => ({
  type: ROIAnalyticsActions.ClickedToGenerateROIReport,
  payload,
});

export const generatedROIReportAnalytics = (payload: IReport) => ({
  type: ROIAnalyticsActions.GeneratedROIReport,
  payload,
});

export const downloadedROIReportAnalytics = (payload: IReportBasicData) => ({
  type: ROIAnalyticsActions.DownloadedROIReport,
  payload,
});

export const editedROIReportAnalytics = (payload: IReportBasicData) => ({
  type: ROIAnalyticsActions.EditedROIReport,
  payload,
});

export const revealedLatestROIReportAnalytics = (payload: IReportBasicData) => ({
  type: ROIAnalyticsActions.RevealedLatestROIReport,
  payload,
});

export const viewedROIReportAnalytics = (payload: IReportBasicData) => ({
  type: ROIAnalyticsActions.ViewedROIReport,
  payload,
});
