import { values } from 'ramda';
import { IBrochure } from '@bridebook/models/source/models/Suppliers/Brochures.types';
import { IS3File } from '@bridebook/ui';
import { getBrochureError } from 'lib/pricing/utils';
import { IBrochureRTDB } from 'lib/supplier/types';
import { PricingActionTypes } from '../action-types';
import { BrochureUploadFailReason } from '../types';

export const onFirestoreBrochures = (payload: Record<string, IBrochure>) => ({
  type: PricingActionTypes.ON_FIRESTORE_BROCHURES,
  payload: values(payload),
});

export const addSupplierBrochure = (s3file: IS3File, fileBlob: Record<string, any>) => ({
  type: PricingActionTypes.ADD_SUPPLIER_BROCHURE,
  payload: { s3file, fileBlob },
});

export const changeBrochureTitle = (brochure: IBrochureRTDB | IBrochure) => ({
  type: PricingActionTypes.CHANGE_BROCHURE_TITLE,
  payload: { brochure },
});

export const dropSupplierBrochure = (brochure: IBrochureRTDB | IBrochure) => ({
  type: PricingActionTypes.DROP_SUPPLIER_BROCHURE,
  payload: { brochure },
});

export const supplierBrochuresError = (reason: BrochureUploadFailReason) => () => ({
  type: 'SUPPLIER_BROCHURES_ERROR',
  payload: { message: getBrochureError(reason) },
});
