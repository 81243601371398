import { Middleware } from '@reduxjs/toolkit';
import abTestingAnalytics from 'lib/abtesting/analytics';
import couplesAnalytics from 'lib/couples/analytics';
import navigationAnalytics from 'lib/navigation/analytics';
import ROIAnalytics from 'lib/roi/analytics';
import supplierAnalytics from 'lib/supplier/analytics';
import { IApplicationState, IDeps } from 'lib/types';
import uiAnalytics from 'lib/ui/analytics';
import CmsAnalyticsContext from 'lib/utils/cms-analytics-context';
import appAnalytics from './app/analytics';
import authAnalytics from './auth/analytics';
import couplesActivityAnalytics from './couples-activity/analytics';
import enquiriesAnalytics from './enquiries/analytics';
import followUpMessagesAnalytics from './follow-up-messages/analytics';
import getListedAnalytics from './get-listed/analytics';
import groupDashboardAnalytics from './groups/analytics';
import messageTemplatesAnalytics from './message-templates/analytics';
import offersAnalytics from './offers/analytics';
import payPerEnquiryAnalytics from './pay-per-enquiry/analytics';
import photosAnalytics from './photos/analytics';
import premiumAnalytics from './premium/analytics';
import recommendationsAnalytics from './recommendations/analytics';
import searchAnalytics from './search/analytics';
import settingsAnalytics from './settings/analytics';
import supplierStatusAnalytics from './supplier-status/analytics';
import tidyYourInboxAnalytics from './tidy-your-inbox/analytics';
import usersAnalytics from './users/analytics';

// @deprecated Please use the new analytics service
const AnalyticsMiddleware: (
  bridebookAnalytics: CmsAnalyticsContext,
) => Middleware<IDeps, IApplicationState> =
  (bridebookAnalytics: CmsAnalyticsContext) =>
  ({ getState }) =>
  (next) =>
  (action) => {
    if (!process.browser) return next(action);
    authAnalytics(action, bridebookAnalytics, getState);
    appAnalytics(action, bridebookAnalytics, getState);
    searchAnalytics(action, bridebookAnalytics);
    supplierAnalytics(action, bridebookAnalytics, getState);
    uiAnalytics(action, bridebookAnalytics, getState);
    usersAnalytics(action, bridebookAnalytics, getState);
    settingsAnalytics(action, bridebookAnalytics, getState);
    recommendationsAnalytics(action, bridebookAnalytics, getState);
    photosAnalytics(action, bridebookAnalytics, getState);
    enquiriesAnalytics(action, bridebookAnalytics, getState);
    offersAnalytics(action, bridebookAnalytics, getState);
    getListedAnalytics(action, bridebookAnalytics, getState);
    supplierStatusAnalytics(action, bridebookAnalytics, getState);
    premiumAnalytics(action, bridebookAnalytics, getState);
    payPerEnquiryAnalytics(action, bridebookAnalytics, getState);
    couplesAnalytics(action, bridebookAnalytics, getState);
    messageTemplatesAnalytics(action, bridebookAnalytics, getState);
    followUpMessagesAnalytics(action, bridebookAnalytics, getState);
    groupDashboardAnalytics(action, bridebookAnalytics, getState);
    tidyYourInboxAnalytics(action, bridebookAnalytics, getState);
    ROIAnalytics(action, bridebookAnalytics, getState);
    couplesActivityAnalytics(action, bridebookAnalytics, getState);
    navigationAnalytics(action, bridebookAnalytics, getState);
    abTestingAnalytics(action, bridebookAnalytics);

    return next(action);
  };

export default AnalyticsMiddleware;
