import { getI18n } from 'react-i18next';

function notificationMessages(type: string, numberOfPhotos: number = 0) {
  const i18n = getI18n();
  const notifications = {
    unsaved: {
      type: 'custom',
      text: i18n.t('common:unsavedChanges.snackbar'),
      buttonText: i18n.t('common:button.save'),
    },
    error: {
      type: 'error',
      text: i18n.t('common:fieldsMissing.snackbar'),
      buttonText: 'Try to save again',
    },
    success: {
      type: 'success',
      text: i18n.t('common:savedChanges.success'),
      buttonText: i18n.t('common:button.continueToPhotos'),
    },
    uploaded: {
      type: 'success',
      text:
        numberOfPhotos > 1
          ? i18n.t('common:photosUploadSuccessful', { numberOfPhotos })
          : i18n.t('common:photoUploadSuccessful', {
              count: numberOfPhotos,
            }),
      buttonText: i18n.t('common:button.continueToPhotos'),
    },
    brochuresUploaded: {
      type: 'success',
      text: `${i18n.t('common:uploadSuccessful.snackbar')} ${i18n.t(
        'common:nameYourDocument.snackbar',
      )} ${new Date().getFullYear()}`,
      buttonText: '',
    },
    brochuresError: {
      type: 'error',
      text: i18n.t('common:brochureUploadError.snackbar'),
      buttonText: '',
    },
  };
  return notifications[type];
}

export default notificationMessages;
