import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models';
import { ISupplier_Translations } from '@bridebook/models/source/models/Suppliers.types';
import { appError } from 'lib/app/actions';
import { getScopedContentTranslations } from 'lib/content-translations/selectors';
import { clearTempContentTranslations } from 'lib/content-translations/slice';
import { ISaveFormSection, SupplierActionTypes } from 'lib/supplier/action-types';
import { saveFormSectionSuccess } from 'lib/supplier/actions';
import { FormSections } from 'lib/supplier/types';
import { getHandleSaveSupplierDataPromise } from 'lib/supplier/utils/get-save-all-supplier-data-promise';
import { IEpic, IEpicDeps } from 'lib/types';

/**
 * Epic that updates supplier fields in database, when a form section is saved.
 **/
export const saveFormSectionEpic: IEpic<ISaveFormSection, any> = (
  action$: Observable<ISaveFormSection>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(SupplierActionTypes.SAVE_FORM_SECTION),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const { formSection, scope, translationPaths } = action.payload;
      const _translations = getScopedContentTranslations<ISupplier_Translations>(
        state,
        scope,
        translationPaths,
      );
      const {
        supplier: { supplier, formSectionsUpdates },
        users: { activeSupplierAccessControl: activeSupplier },
      } = state;

      const updateData = formSectionsUpdates?.[formSection] || {};

      if (!supplier || !activeSupplier?.id) return of();

      const getSaveFormSectionPromise = async () => {
        const supplierRef = Suppliers._.getById(activeSupplier.id);
        return supplierRef.set({
          ...updateData,
          ...(_translations && { _translations }),
        });
      };

      const getPromise =
        formSection === FormSections.Contact
          ? getHandleSaveSupplierDataPromise({
              formData: updateData,
              supplier,
              activeSupplier,
              _translations,
            })
          : getSaveFormSectionPromise;

      return from(getPromise()).pipe(
        mergeMap(() => [saveFormSectionSuccess(formSection), clearTempContentTranslations(scope)]),
        catchError((error) =>
          of(appError({ error, feature: `Supplier form section: ${formSection}` })),
        ),
      );
    }),
  );
