import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { IAccountLocation } from './types';

export const unifyAddress = (
  code: CountryCodes,
  location: Partial<IAccountLocation>,
): Partial<IAccountLocation> => {
  if (code === CountryCodes.IE) {
    // Cleaning Postal Town e.g. 'Dublin 8' turns to 'Dublin'
    const postal_town = location.postal_town?.replace(/[0-9]/g, '').trim();
    // County is returned in administrative_area_level_1, trying to resolve level 2 and swap levels
    const administrative_area_level_2 = location.administrative_area_level_1;
    if (administrative_area_level_2) {
      const administrative_area_level_1 = findIrelandProvince(administrative_area_level_2);
      if (administrative_area_level_1) {
        return {
          ...location,
          postal_town,
          administrative_area_level_1,
          administrative_area_level_2,
        };
      }
    }
  }
  return location;
};

export const findIrelandProvince = (countyName: string): string | null => {
  const comparableCountyName = countyName.toLowerCase().trim();
  const provinces: string[] = Object.keys(provinceToCounties);
  for (const province of provinces) {
    const counties = provinceToCounties[province];
    for (const county of counties) {
      if (comparableCountyName.endsWith(county.toLowerCase())) {
        return province;
      }
    }
  }
  return null;
};

// https://www.notion.so/bridebook/IE-Search-Pages-d17dccb912b34ef48cc787888fd57d9a
const provinceToCounties: Record<string, string[]> = {
  Connacht: ['Leitrim', 'Mayo', 'Roscommon', 'Sligo', 'Galway', 'Castlebar', 'Ballina', 'Clifden'],
  Leinster: [
    'Carlow',
    'Kildare',
    'Laois',
    'Longford',
    'Louth',
    'Meath',
    'Offaly',
    'Kilkenny',
    'Westmeath',
    'Wexford',
    'Wicklow',
    'Dublin',
    'Newbridge',
    'Enniscorthy',
    'Mullingar',
    'Athlone',
    'Dundalk',
    'Gorey',
  ],
  Munster: [
    'Clare',
    'Kerry',
    'Tipperary',
    'Limerick',
    'Cork',
    'Waterford',
    'Kenmare',
    'Killarney',
    'Miltown Malbay',
    'Clonakilty',
    'Adare',
    'Bantry',
    'Doolin',
  ],
  Ulster: ['Cavan', 'Donegal', 'Monaghan', 'Letterkenny'],
};
