import { BasicKeyedStorageManager } from '@bridebook/toolbox/src/storage-managers';

export interface IReadItemsStorage {
  bookings?: number;
  reviews?: number;
}

export const ReadItemsStorage = (id: string) =>
  new BasicKeyedStorageManager<IReadItemsStorage>({
    storageKey: 'unread-items',
    id,
  });
