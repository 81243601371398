import { deleteField } from 'firebase/firestore';
import { values } from 'ramda';
import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models';
import { Achievements } from '@bridebook/models/source/models/Suppliers/Achievements';
import { IAchievement } from '@bridebook/models/source/models/Suppliers/Achievements.types';
import { appError } from 'lib/app/actions';
import { IEpic, IEpicDeps } from 'lib/types';
import { AchievementsActionTypes, SaveAchievementsAction } from '../action-types';
import { getAccreditations, getAwards, getMedia } from '../selectors';
import { IAchievementOrDraft } from '../types';

const findAchievementsToAdd = (
  reduxAchievements: IAchievementOrDraft[],
  firebaseAchievements: IAchievement[],
): IAchievementOrDraft[] => {
  const differences: IAchievementOrDraft[] = [];

  reduxAchievements.forEach((item1) => {
    const item2 = firebaseAchievements.find((i) => i.id === item1.id);
    if (
      !item2 ||
      (item2 &&
        (item1.type !== item2.type || item1.link !== item2.link || item1.name !== item2.name))
    ) {
      differences.push(item1);
    }
  });

  return differences;
};

export const saveAchievementsEpic: IEpic<SaveAchievementsAction, any> = (
  action$: Observable<SaveAchievementsAction>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(AchievementsActionTypes.SAVE_ACHIEVEMENTS),
    withLatestFrom(state$),
    mergeMap(([_, state]) => {
      const getPromise = async (): Promise<any> => {
        const activeSupplier = state.users.activeSupplierAccessControl;

        if (!activeSupplier) {
          throw new Error('saveAchievementsEpic: no active supplier');
        }

        const supplierRef = Suppliers._.getById(activeSupplier.id);
        const allAchievements = [
          ...getMedia(state),
          ...getAwards(state),
          ...getAccreditations(state),
        ];

        const firebaseAchievements = await supplierRef.Achievements.query().get();
        const achievementsToUpdate = findAchievementsToAdd(
          allAchievements,
          values(firebaseAchievements),
        );

        if (!achievementsToUpdate.length) return of();

        supplierRef.Achievements.begin();

        achievementsToUpdate.forEach((achievement) => {
          const { name, id, link } = achievement;
          const hasName = name.trim() !== '';

          if (hasName) {
            if (!id) {
              supplierRef.Achievements.push().create(Achievements.new('_', achievement));
            } else {
              if (!link) {
                supplierRef.Achievements.getById(id).set({ ...achievement, link: deleteField() });
              } else {
                supplierRef.Achievements.getById(id).set({ ...achievement });
              }
            }
          } else if (id) {
            supplierRef.Achievements.getById(id).delete(false);
          } else {
            return of();
          }
        });
        return supplierRef.Achievements.commit();
      };

      return from(getPromise()).pipe(
        map(() => ({ type: AchievementsActionTypes.SAVE_ACHIEVEMENTS_SUCCESS })),
        catchError((error) => of(appError({ error, feature: 'Achievements' }))),
      );
    }),
  );
