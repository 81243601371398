import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mapTo, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models';
import type { IBrochure } from '@bridebook/models/source/models/Suppliers/Brochures.types';
import type { IEpicDeps } from 'lib/types';
import { appError } from '../../app/actions';
import { IChangeBrochureTitleAction, PricingActionTypes } from '../action-types';

export const dropSupplierBrochureEpic = (
  action$: Observable<IChangeBrochureTitleAction>,
  { state$, bbanalytics }: IEpicDeps,
) =>
  action$.pipe(
    ofType(PricingActionTypes.DROP_SUPPLIER_BROCHURE),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const { brochure } = action.payload;

      const activeSupplier = state.users.activeSupplierAccessControl;
      if (!activeSupplier?.id) return of();

      const getPromise = async () => {
        const drop = async () => {
          const id = (brochure as IBrochure).id;

          return Suppliers._.getById(activeSupplier.id).Brochures.getById(id).delete();
        };

        return drop();
      };

      return from(getPromise()).pipe(
        tap(() =>
          bbanalytics.supplier.pricing.deleted({
            updatedSubsection: 'brochure',
            supplierBrochureId: (brochure as IBrochure).id,
          }),
        ),
        mapTo({
          type: PricingActionTypes.DROP_SUPPLIER_BROCHURE_SUCCESS,
        }),
        catchError((error) =>
          of(
            { type: PricingActionTypes.DROP_SUPPLIER_BROCHURE_ERROR },
            appError({ error, feature: 'Pricing' }),
          ),
        ),
      );
    }),
  );
