import { Action, State } from 'lib/types';
import { toUrlQuery } from 'lib/utils';
import CmsAnalyticsContext from 'lib/utils/cms-analytics-context';

const appAnalytics = (
  action: Action,
  bridebookAnalytics: CmsAnalyticsContext,
  getState: () => State,
): void => {
  switch (action.type) {
    case 'ROUTE_CHANGE_COMPLETE': {
      const { host } = getState().app.device;
      const { url, query, previousPath } = action.payload;
      const pathname = url.split('?')[0];
      const prevPathname = previousPath ? previousPath.split('?')[0] : '';
      if (pathname !== prevPathname) {
        bridebookAnalytics.page(pathname, {
          path: pathname,
          name: pathname,
          title: pathname,
          referrer: toUrlQuery(query),
          url: `https://${host}${url}`,
        });
      }
      break;
    }
    default:
      break;
  }
};
export default appAnalytics;
