import { IDatePicker } from '@bridebook/toolbox/src/datepicker/types';
import { LeadEventType } from '@bridebook/toolbox/src/supplier/activity/types';
import { IBookingFile } from '@bridebook/toolbox/src/types';

export enum CouplesTableSort {
  recentlyAdded = 'recentlyAdded',
  weddingDate = 'weddingDate',
  coupleName = 'coupleName',
  guestCount = 'guestCount',
}

export interface ICoupleBooking {
  byCouple: boolean;
  bySupplier: boolean;
  collaborating: boolean;
  createdAt: number;
  email: string;
  guestsInitialTarget: number;
  partnerName1: string;
  partnerName2: string;
  phone: string;
  profileId: string;
  profilePhoto: string;
  shortlistSizeVenues: number;
  tasksDone: number;
  tasksTotal: number;
  userId: string;
  weddingDate: number;
}

export interface ICoupleBookingDetails {
  collaborating: boolean;
  createdAt: number;
  email: string;
  guestsInitialTarget: number;
  partnerName1: string;
  partnerName2: string;
  phone: string;
  profileId: string;
  profilePhoto: string;
  shortlistSizeVenues: number;
  bookedSuppliersCount: number;
  tasksDone: number;
  tasksTotal: number;
  userId: string;
  weddingDate: number;
}

export interface ICouplesState {
  bookings: ICoupleBooking[] | null;
  total: number;
  bookingsByYear: Record<string, ICoupleBooking[]> | null;
  sortBy: CouplesTableSort;
  yearFilter: string;
  loaded: boolean;
  openedBooking: ICoupleBookingDetails | null;
  openedBookingId: string | null;
  // Files are null when not yet loaded
  openedBookingFiles: IBookingFile[] | null;
}

export interface ICoupleActivity {
  eventType: LeadEventType;
  createdAt: number;
  weddingId: string;
  budget: number;
  weddingPartnerName1: string;
  weddingPartnerName2: string;
  weddingDate: IDatePicker;
  weddingGuestsCount?: number;
  budgetRange: number[];
  budgetRangeLabel: string;
  currency: string;
  bookedSupplierId: string;
  enquiryId: string;
  contactedCoupleTime: number;
}

export interface ISendContactCoupleMessage {
  supplierId: string;
  weddingId: string;
  eventType: LeadEventType;
  message: string;
  supplierUserId: string;
}
