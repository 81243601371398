import { IReport } from '@bridebook/models/source/models/Suppliers/Reports.types';

export const mapROIReportToAnalyticsPayload = ({
  numberOfEnquiries,
  estimatedNumberOfBookings,
  grossRevenue,
  totalSubscriptionCost,
  revenueROI,
  grossProfit,
  grossProfitROI,
  reportStartDate,
  reportEndDate,
  id: reportId,
  numberOfIntroductions,
  numberOfViews,
  numberOfShortlists,
  numberOfRedirectsToWeb,
  additionalBookingsEstimate,
  additionalRevenueEstimate,
  countyPosition,
  countryPosition,
  profileScore,
  responseScore,
  isCountyHero,
  additionalAreas,
  isVenueTypeHero,
  isPowerMatchActive,
  isCoupleTargetingActive,
  isCoupleIntelActive,
  isAutoFollowUpsActive,
  isSocialSupportActive,
  isLostLeadsActive,
  lastTierUpdateDate,
}: IReport) => ({
  numberOfEnquiries,
  estimatedNumberOfBookings,
  grossRevenue,
  totalSubscriptionCost,
  revenueROI,
  grossProfit,
  grossProfitROI,
  reportStartDate,
  reportEndDate,
  reportId,
  numberOfIntroductions,
  numberOfViews,
  numberOfShortlists,
  numberOfRedirectsToWeb,
  additionalBookingsEstimate,
  additionalRevenueEstimate,
  countyPosition,
  countryPosition,
  profileScore,
  responseScore,
  isCountyHero,
  multiCounty: additionalAreas.length > 0,
  isVenueTypeHero,
  isPowerMatchActive,
  isCoupleTargetingActive,
  isCoupleIntelActive,
  isAutoFollowUpsActive,
  isSocialSupportActive,
  isLostLeadsActive,
  lastTierUpdateDate,
});
