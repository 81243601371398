import { original, produce } from 'immer';
import { lensPath, set, union, view, without } from 'ramda';
import { IBrochure } from '@bridebook/models/source/models/Suppliers/Brochures.types';
import { IPackage } from '@bridebook/models/source/models/Suppliers/Packages.types';
import { ISupplierPackage } from '@bridebook/toolbox/src/map-packages-to-sections';
import { Action, IReducersImmer } from '../types';
import {
  IOnFirestoreBrochuresAction,
  IOnFirestorePackagesAction,
  IOnFirestoreSupplierPackagesAction,
  IUpdatePackageCheckboxGroupAction,
  IUpdatePackageFieldAction,
  PricingActionTypes,
} from './action-types';

export interface IPricingState {
  packagesSections: Record<string, IPackage>;
  brochures: IBrochure[];
  supplierPackages: ISupplierPackage[];
  supplierPackagesLoaded: boolean;
}

const initialState: IPricingState = {
  packagesSections: {},
  brochures: [],
  supplierPackages: [],
  supplierPackagesLoaded: false,
};

const reducers: IReducersImmer<IPricingState> = (draft: IPricingState) => ({
  // Packages
  [PricingActionTypes.ON_FIRESTORE_PACKAGES]: (action: IOnFirestorePackagesAction) => {
    draft.packagesSections = action.payload;
  },

  [PricingActionTypes.ON_FIRESTORE_SUPPLIER_PACKAGES]: (
    action: IOnFirestoreSupplierPackagesAction,
  ) => {
    const sortedPackages = Object.values(action.payload).sort(
      (a, b) => a.order - b.order || a.price - b.price,
    );
    draft.supplierPackages = sortedPackages;
    draft.supplierPackagesLoaded = true;
  },

  [PricingActionTypes.UPDATE_PACKAGE_CHECKBOX_GROUP]: (
    action: IUpdatePackageCheckboxGroupAction,
  ) => {
    const {
      payload: { name, value },
    } = action;
    const path = name.split('.');
    const prop = path.pop();
    const lens = lensPath(path);
    const originalPackagesSections = original(draft.packagesSections);

    const current = (view(lens, originalPackagesSections) || []) as string[];
    const addRemoveItem = value ? union(current, [prop]) : without([prop], current);

    draft.packagesSections = set(lens, addRemoveItem, originalPackagesSections || {});
  },

  [PricingActionTypes.UPDATE_PACKAGE_FIELD]: (action: IUpdatePackageFieldAction) => {
    const {
      payload: { packageSection, name, value },
    } = action;
    const lens = lensPath([packageSection, name]);
    const originalPackagesSections = original(draft.packagesSections);

    draft.packagesSections = set(lens, value, originalPackagesSections);
  },

  // Brochures
  [PricingActionTypes.ON_FIRESTORE_BROCHURES]: (action: IOnFirestoreBrochuresAction) => {
    draft.brochures = action.payload;
  },
});

const reducer = (state: IPricingState = initialState, action: Action): IPricingState => {
  try {
    return produce(state, (draft) => reducers(draft, state)[action.type](action));
  } catch (err) {
    return state;
  }
};

export default reducer;
