import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mapTo, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models';
import { Brochures } from '@bridebook/models/source/models/Suppliers/Brochures';
import { IEpicDeps } from 'lib/types';
import { appError } from '../../app/actions';
import { IAddSupplierBrochureAction, PricingActionTypes } from '../action-types';

export const addBrochureEpic = (
  action$: Observable<IAddSupplierBrochureAction>,
  { state$, bbanalytics }: IEpicDeps,
) =>
  action$.pipe(
    ofType(PricingActionTypes.ADD_SUPPLIER_BROCHURE),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const { s3file, fileBlob } = action.payload;

      const activeSupplier = state.users.activeSupplierAccessControl;
      if (!activeSupplier?.id) return of();

      const getPromise = async () => {
        const save = async () => {
          const supplierRef = Suppliers._.getById(activeSupplier.id);

          const { filename } = s3file;

          const brochure = {
            name: filename.replace(/.*\//, '').replace('.pdf', ''),
            file: filename,
          };

          const document = await supplierRef.Brochures.push().create(Brochures.new('_', brochure));

          return { brochure, fileBlob, document };
        };

        return save();
      };

      return from(getPromise()).pipe(
        tap(({ document }) =>
          bbanalytics.supplier.pricing.added({
            updatedSubsection: 'brochure',
            supplierBrochureId: document.id,
          }),
        ),
        mapTo({
          type: PricingActionTypes.ADD_SUPPLIER_BROCHURE_SUCCESS,
        }),

        catchError((error) =>
          of(
            { type: PricingActionTypes.ADD_SUPPLIER_BROCHURE_ERROR },
            appError({ error, feature: 'Pricing' }),
          ),
        ),
      );
    }),
  );
