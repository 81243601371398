import { dashify } from '@bridebook/toolbox/src';
import { CheckoutSuccessQueryProps } from 'pages/checkout/success';
import { NavSection } from 'lib/navigation/types';
import { OfferTypes } from 'lib/offers/constants';
import { toUrlQuery } from 'lib/utils';
import { addUrlPrefixes } from 'lib/utils/add-url-prefixes';
import { env } from './env';

/**
 * Object describing all the URLs in the app. Any changes here should be double-checked with the
 * redirects in next.config.js files.
 */
export const UrlHelper = addUrlPrefixes({
  [NavSection.Couples]: {
    enquiries: {
      manager: '/enquiries',
      enquiry: (id: string) => `/enquiries/${id}`,
      payment: {
        success: (query: Record<string, string> = {}) =>
          `/enquiries?payment=success${toUrlQuery(query, true)}`,
        failed: (query: Record<string, string> = {}) =>
          `/enquiries?payment=failed${toUrlQuery(query, true)}`,
      },
    },
    bookings: {
      main: '/bookings',
      details: (id: string) => `/bookings/${id}`,
    },
    couplesActivity: '/couples-activity',
    enquirySettings: '/enquiries/settings',
    addMessageTemplate: '/enquiries/settings/templates/add',
    editMessageTemplates: (id: string) => `/enquiries/settings/templates/edit/${id}`,
    addFollowUpMessage: '/enquiries/settings/follow-ups/add',
    editFollowUpMessage: (id: string) => `/enquiries/settings/follow-ups/edit/${id}`,
  },

  [NavSection.Performance]: {
    overview: '/overview',
    competitors: '/competitors',
    intelligence: '/intelligence',
    searchOptimisation: '/search-optimisation',
    roiReport: '/roi/report',
    marketInsight: '/market-insights',
  },

  [NavSection.GrowYourBusiness]: {
    coupleTargeting: '/couple-targeting',
    specialOffers: {
      home: '/special-offers',
      form: (type: OfferTypes) => `/special-offers/${dashify(type)}`,
    },
    fairs: '/fairs',
    upgrade: '/upgrade',
    enquiryWidget: '/enquiry-widget',
    badges: '/badges',
    locations: '/locations',
  },

  [NavSection.Profile]: {
    overview: '/overview',
    preview: '/preview',
    improve: '/improve',
    reviews: '/reviews',
    testimonials: '/testimonials',
    realWeddings: '/real-weddings',
    videoTours: '/video-tours',
    recommendations: '/recommendations',
    photos: '/photos',
    videos: '/videos',
    pricing: '/pricing',
    aboutYou: '/about-you',
  },

  [NavSection.KnowledgeHub]: {
    institute: {
      main: '/institute',
      page: (id: string) => `/institute/${id}`,
    },
  },

  [NavSection.HelpAndSupport]: {
    featureTutorials: '/feature-tutorials',
    faq: '/faq',
    contact: '/contact',
  },

  auth: {
    login: '/login',
    signUp: '/signup',
    forgotPassword: '/forgot-password',
    invite: (params?: { id: string; nonce: string }) => ({
      href: `/signup-invite${toUrlQuery(params || {})}`,
    }),
  },

  checkout: {
    supplierPro: (priceId: string) => `/supplierpro/${priceId}`,
    venuePro: (priceId: string) => `/venuepro/${priceId}`,
    success: (query: CheckoutSuccessQueryProps) => `/success${toUrlQuery(query)}`,
  },

  account: {
    settings: '/settings',
    userPermissions: '/user-permissions',
  },

  admin: {
    addSupplier: '/add-supplier',
    banners: '/banners',
    search: '/search',
    abTests: '/ab-tests',
    searchCollections: '/search-presets',
    todayTasks: '/today-tasks',
  },

  index: '/',
  dashboard: '/dashboard',
  preview: '/preview',
  purchases: '/purchases',
  teamChat: '/team-chat',
  reviewUrl: (locale: string, supplierPublicId: string) =>
    `${env.COUPLESIDE_URL}/${locale}/reviews/${supplierPublicId}`,
});

export const loggedOutOnlyPaths = Object.values(UrlHelper.auth).map((value) => {
  if (typeof value === 'string') {
    return value;
  } else if (typeof value === 'function') {
    return value().href;
  }
  return '';
});
