import { keys } from 'ramda';
import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models';
import { PackagesSections } from '@bridebook/toolbox/src/map-packages-to-sections';
import { getContentTranslationsForPackages } from 'lib/content-translations/selectors';
import { clearTempContentTranslations } from 'lib/content-translations/slice';
import { packagesDefaults } from 'lib/pricing/constants';
import { IEpicDeps } from 'lib/types';
import { appError } from '../../app/actions';
import { ISavePackagesAction, PricingActionTypes } from '../action-types';

export const savePackagesEpic = (action$: Observable<ISavePackagesAction>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(PricingActionTypes.SAVE_PACKAGES),
    withLatestFrom(state$),
    mergeMap(([, state]) => {
      const {
        pricing: { packagesSections },
      } = state;

      const translationsToUpdate = getContentTranslationsForPackages(state);
      const activeSupplier = state.users.activeSupplierAccessControl;
      if (!activeSupplier || !activeSupplier.id) return of();

      const getPromise = async () => {
        const supplierRef = Suppliers._.getById(activeSupplier.id);
        supplierRef.Packages.begin();
        await Promise.all(
          keys(packagesSections).map((packageSection) => {
            const data = packagesSections[packageSection];
            const _translations = translationsToUpdate.find((p) => p.id === data.id)?._translations;
            let updateObject = {
              ...data,
              ...(_translations && { _translations }),
            };
            // if no 'id' proceeding with 'create new' logic.
            if (!updateObject.id) {
              const defaults = packagesDefaults[packageSection as PackagesSections];
              if (defaults) {
                updateObject = { ...defaults, ...updateObject };
              }
              return supplierRef.Packages.push().create(updateObject);
            }
            const supplierPackageRef = supplierRef.Packages.getById(updateObject.id);
            supplierPackageRef.set(updateObject);
            return supplierPackageRef;
          }),
        );

        return supplierRef.Packages.commit();
      };

      return from(getPromise()).pipe(
        map(() => ({
          type: PricingActionTypes.SAVE_PACKAGES_SUCCESS,
        })),
        catchError((error) =>
          of(
            { type: PricingActionTypes.SAVE_PACKAGES_ERROR },
            clearTempContentTranslations(),
            appError({ error, feature: 'Pricing' }),
          ),
        ),
      );
    }),
  );
