import { Photo } from 'lib/photos/types';
import { SupplierActionTypes } from 'lib/supplier/action-types';
import { updateSupplierData } from 'lib/supplier/actions/form';
import { IAboutYouInfo, ITargeting, SupplierAction } from 'lib/supplier/types';
import { InputEvent } from 'lib/types';

export function resetSupplierNotification(): SupplierAction {
  return {
    type: 'RESET_SUPPLIER_NOTIFICATION',
  };
}

export function supplierPhotosUploaded(photos: Array<Photo>) {
  return {
    type: 'SUPPLIER_PHOTOS_UPLOADED',
    payload: photos.length,
  };
}

export const savedSupplierInfoOnCmsTargetingAnalytics = (targeting: ITargeting) => ({
  type: 'SAVED_SUPPLIER_INFO_ON_CMS_TARGETING_ANALYTICS',
  payload: { targeting },
});

export const savedSupplierConciergeOptOutAnalytics = (payload: {
  conciergeOptOut: boolean;
  id?: string;
}) => ({
  type: 'SAVED_SUPPLIER_CONCIERGE_OPT_OUT_ANALYTICS',
  payload,
});

export const fetchCountries = () => ({
  type: SupplierActionTypes.FETCH_COUNTRIES,
});

export const saveAboutYouDetails = (details: IAboutYouInfo) =>
  updateSupplierData({
    target: { name: 'about', value: details },
  });

export const saveAboutYouDescription = (description: string) =>
  updateSupplierData({
    target: { name: 'description.short', value: description },
  } as InputEvent);
