import {
  addSupplierBrochure,
  changeBrochureTitle,
  deleteSupplierPackage,
  dropSupplierBrochure,
  onFirestoreBrochures,
  onFirestorePackages,
  onFirestoreSupplierPackages,
  savePackages,
  saveSupplierPackage,
  updatePackageCheckboxGroup,
  updatePackageField,
} from './actions';

export enum PricingActionTypes {
  // Packages
  INIT_PACKAGES_LISTENER = '[Pricing] Init packages listener',
  ON_FIRESTORE_PACKAGES = '[Pricing] On Firestore packages',
  SAVE_PACKAGES_ERROR = '[Pricing] Save packages to Firestore error',
  SAVE_PACKAGES_SUCCESS = '[Pricing] Save packages to Firestore success',
  SAVE_PACKAGES = '[Pricing] Initialize save packages to Firestore',
  STOP_PACKAGES_LISTENER = '[Pricing] Stop packages listener',
  UPDATE_PACKAGE_CHECKBOX_GROUP = '[Pricing] Update package checkbox group',
  UPDATE_PACKAGE_FIELD = '[Pricing] Update package field',
  // Brochures
  ADD_SUPPLIER_BROCHURE = '[Pricing] Add supplier brochure',
  ADD_SUPPLIER_BROCHURE_ERROR = '[Pricing] Add supplier brochure error',
  ADD_SUPPLIER_BROCHURE_SUCCESS = '[Pricing] Add supplier brochure success',
  CHANGE_BROCHURE_TITLE = '[Pricing] Change supplier brochure title',
  CHANGE_BROCHURE_TITLE_ERROR = '[Pricing] Change supplier brochure title error',
  CHANGE_BROCHURE_TITLE_SUCCESS = '[Pricing] Change supplier brochure title success',
  DROP_SUPPLIER_BROCHURE = '[Pricing] drop supplier brochure',
  DROP_SUPPLIER_BROCHURE_ERROR = '[Pricing] drop supplier brochure error',
  DROP_SUPPLIER_BROCHURE_SUCCESS = '[Pricing] drop supplier brochure success',
  INIT_BROCHURES_LISTENER = '[Pricing] Init brochures listener',
  ON_FIRESTORE_BROCHURES = '[Pricing] On Firestore brochures',
  STOP_BROCHURES_LISTENER = '[Pricing] Stop brochures listener',
  SAVE_SUPPLIER_PACKAGE = '[Pricing] Initialize save supplier package to Firestore',
  ON_FIRESTORE_SUPPLIER_PACKAGES = '[Pricing] On Firestore supplier packages',
  DELETE_SUPPLIER_PACKAGE = '[Pricing] Delete supplier package',
}

// Packages
export type IOnFirestorePackagesAction = ReturnType<ReturnType<typeof onFirestorePackages>>;
export type ISavePackagesAction = ReturnType<typeof savePackages>;
export type IUpdatePackageCheckboxGroupAction = ReturnType<typeof updatePackageCheckboxGroup>;
export type IUpdatePackageFieldAction = ReturnType<ReturnType<typeof updatePackageField>>;
// Brochures
export type IAddSupplierBrochureAction = ReturnType<typeof addSupplierBrochure>;
export type IChangeBrochureTitleAction = ReturnType<typeof changeBrochureTitle>;
export type IDropSupplierBrochureAction = ReturnType<typeof dropSupplierBrochure>;
export type IOnFirestoreBrochuresAction = ReturnType<typeof onFirestoreBrochures>;
export type ISaveSupplierPackageAction = ReturnType<typeof saveSupplierPackage>;
export type IOnFirestoreSupplierPackagesAction = ReturnType<
  ReturnType<typeof onFirestoreSupplierPackages>
>;

export type IDeleteSupplierPackageAction = ReturnType<typeof deleteSupplierPackage>;
