import { original, produce } from 'immer';
import { AccessControlActionTypes } from 'lib/access-control/action-types';
import { Action, IReducersImmer } from '../types';
import {
  EnquiriesActionTypes,
  IEditEnquiryInfoSuccessAction,
  IFetchEnquiries,
  IFetchEnquiriesSuccess,
  IFetchEnquirySuccess,
  ISetEnquiryProgress,
  ISetEnquiryStatus,
  ISetOwnerFilter,
  IUpdateEnquiryOwner,
  IUpdateEnquiryScheduledFollowUp,
  IUpdateEnquiryWidgetSettings,
  IUpdateNotesCountSuccess,
} from './action-types';
import EmptyStatuses from './emptyStatusesRTDB';
import { enquiryWidgetSettingsDefault } from './enquiry-widget-settings-default';
import NumStatuses from './numStatuses';
import { IEnquriesState } from './types';

const initialState: IEnquriesState = {
  list: [],
  enquiry: null,
  weddingSummary: null,
  nextEnquiryId: null,
  loadedInitial: false,
  loaded: false,
  totalPages: 1,
  page: 0,
  emptyStatuses: { ...EmptyStatuses },
  numStatuses: { ...NumStatuses },
  status: 'inprogress',
  ownerFilter: undefined,
  enquiryWidgetSettings: enquiryWidgetSettingsDefault,
};

const updateProgress = (draft: IEnquriesState, action: any) => {
  const { id, newProgress } = action.payload || {};
  const state = original(draft);
  if (!state) return;
  draft.list = state.list.map((enquiry) =>
    enquiry.id === id ? { ...enquiry, progress: newProgress } : enquiry,
  );
  draft.enquiry = state.enquiry ? { ...state.enquiry, progress: newProgress } : null;
};

const reducers: IReducersImmer<IEnquriesState> = (draft: IEnquriesState) => ({
  [EnquiriesActionTypes.FETCH_ENQUIRIES_SUCCESS]: (action: IFetchEnquiriesSuccess) => {
    const { supplierWeddings, totalPages, page, stats } = action.payload || {};
    draft.list = supplierWeddings;
    draft.loaded = true;
    draft.loadedInitial = true;
    draft.totalPages = totalPages;
    draft.page = page;
    draft.emptyStatuses = { ...EmptyStatuses };
    draft.numStatuses = stats;
  },

  [EnquiriesActionTypes.FETCH_ENQUIRY_START]: () => {
    draft.enquiry = null;
    draft.nextEnquiryId = null;
  },

  [EnquiriesActionTypes.FETCH_ENQUIRY_SUCCESS]: (action: IFetchEnquirySuccess) => {
    const { supplierWedding, next, weddingSummary } = action.payload || {};
    if (!supplierWedding) return;
    draft.enquiry = supplierWedding;
    draft.nextEnquiryId = next || null;
    draft.weddingSummary = weddingSummary || null;
  },

  [EnquiriesActionTypes.UPDATE_NOTES_COUNT_SUCCESS]: (action: IUpdateNotesCountSuccess) => {
    if (!draft.enquiry) return;
    draft.enquiry.notesCount = action.payload;
  },

  [EnquiriesActionTypes.SET_ENQUIRY_STATUS]: (action: ISetEnquiryStatus) => {
    const { status, id, value } = action.payload || {};
    const state = original(draft);
    if (!state) return;

    draft.list = state.list.map((enquiry) =>
      enquiry.id === id ? { ...enquiry, flags: { ...enquiry.flags, [status]: value } } : enquiry,
    );
    draft.enquiry = state.enquiry
      ? { ...state.enquiry, flags: { ...state.enquiry.flags, [status]: value } }
      : null;
  },

  [EnquiriesActionTypes.FETCH_ENQUIRIES]: (action: IFetchEnquiries) => {
    const state = original(draft);
    draft.status = (action.payload && action.payload.status) || state?.status || 'inprogress';
    draft.loaded = false;
  },

  [EnquiriesActionTypes.SET_ENQUIRY_PROGRESS]: (action: ISetEnquiryProgress) => {
    updateProgress(draft, action);
  },

  [EnquiriesActionTypes.SET_ENQUIRY_PROGRESS_SUCCESS]: (action: ISetEnquiryProgress) => {
    updateProgress(draft, action);
  },

  [EnquiriesActionTypes.UPDATE_OWNER]: (action: IUpdateEnquiryOwner) => {
    const { enquiryId, ownerId } = action.payload;
    const state = original(draft);
    if (!state) return;
    const list = state.list.map((enquiry) =>
      enquiry.id === enquiryId ? { ...enquiry, owner: ownerId } : enquiry,
    );
    const enquiry = state.enquiry ? { ...state.enquiry, owner: ownerId } : null;
    draft.list = list;
    draft.enquiry = enquiry;
  },

  [EnquiriesActionTypes.SET_OWNER_FILTER]: (action: ISetOwnerFilter) => {
    const { ownerFilter } = action.payload;
    draft.ownerFilter = ownerFilter;
    draft.loaded = false;
  },

  [AccessControlActionTypes.SWITCH_SUPPLIER]: () => initialState,

  [AccessControlActionTypes.SWITCH_PERMISSION_START]: () => initialState,

  [EnquiriesActionTypes.UPDATE_ENQUIRY_WIDGET_SETTINGS]: (action: IUpdateEnquiryWidgetSettings) => {
    const { name, value } = action.payload;
    draft.enquiryWidgetSettings[name] = value;
  },

  [EnquiriesActionTypes.EDIT_ENQUIRY_INFO_SUCCESS]: (action: IEditEnquiryInfoSuccessAction) => {
    draft.enquiry = action.payload;
  },

  [EnquiriesActionTypes.UPDATE_ENQUIRY_SCHEDULED_FOLLOW_UP]: (
    action: IUpdateEnquiryScheduledFollowUp,
  ) => {
    const state = original(draft);

    if (!state) return;

    draft.enquiry = state.enquiry ? { ...state.enquiry, ...action.payload } : null;
  },
});

const reducer = (state: IEnquriesState = initialState, action: Action): IEnquriesState => {
  try {
    return produce(state, (draft) => reducers(draft)[action.type](action));
  } catch (err) {
    return state;
  }
};

export default reducer;
