import {
  Coupon,
  Customer,
  Item,
  ItemPrice,
  Subscription,
} from 'chargebee-typescript/lib/resources';
import { PaymentMethod } from 'chargebee-typescript/lib/resources/customer';
import { _quote } from 'chargebee-typescript/lib/resources/quote';
import { SubscriptionItem } from 'chargebee-typescript/lib/resources/subscription';
import { PremiumTiers } from '@bridebook/toolbox';
import { IDropdownOption } from '@bridebook/ui';

export enum ChargebeePlanFamily {
  VenuePro = 'VenuePro',
  SupplierPro = 'SupplierPro',
}

// Top-level type of addon
// Used as a custom field in Chargebee: cf_addon_type
export enum ChargebeeAddonTypes {
  TopSpot = 'Top Spot',
  VenueTypeHero = 'Venue Type Hero',
  MultiCounty = 'Multi County',
}

// Identifier of self-serve specified as the Sales Agent in Chargebee
export const SELF_SERVE_AGENT = 'Self-Serve';

// Source of the Chargebee customer, used as a custom field in Chargebee: cf_source
export enum ChargebeeCustomerSource {
  CMS = 'cms', // if upgraded with the quote created in CMS
  SelfServe = 'self-serve', // if upgraded from pricing page with self-checkout
}

export interface ChargebeeEventData {
  eventId: string;
  supplierId: string;
  customerId: string;
  subscriptionId: string;
  priceId: string;
  planTier: PremiumTiers;
  planFamily: ChargebeePlanFamily;
  isActive: boolean;
  addons: Addon[];
  extra: {
    supplierName?: string;
    isSelfServe: boolean;
    price?: number;
    currency?: string;
    // True, if event is re-triggered after previous fail
    isEventRetry?: boolean;
  };
}

// Chargebee Plan with custom fields
export interface PlanWithCustomFields extends Item {
  cf_tier?: string;
  cf_plan_family?: ChargebeePlanFamily;
  metadata?: {
    contractTerms?: IDropdownOption[];
  };
}

export interface PlanPrice extends ItemPrice {
  metadata?: {
    discounts?: Partial<Record<QuoteDiscount['duration_type'], number[]>>;
  };
}

// Chargebee Addon with custom fields
export interface AddonWithCustomFields extends Item {
  cf_addon_type?: ChargebeeAddonTypes;
}

export interface ChargePrice extends ItemPrice {
  itemName?: string;
  itemNameExternal?: string;
}

export interface AddonPrice extends ItemPrice {
  itemName?: string;
  itemNameExternal?: string;
  addonType?: ChargebeeAddonTypes;
  metadata?: {
    contractTerm?: string;
  };
}

// Type guards
export const isPlanPrice = (item: ItemPrice): item is PlanPrice => item.item_type === 'plan';
export const isChargePrice = (item: ItemPrice): item is ChargePrice => item.item_type === 'charge';
export const isAddonPrice = (item: ItemPrice): item is AddonPrice => item.item_type === 'addon';

// Chargebee Customer with custom fields
export interface CustomerWithCustomFields extends Customer {
  cf_supplierid?: string;
  cf_supplier_type?: string;
  cf_sales_agent?: string;
  cf_source?: ChargebeeCustomerSource;
}

// Internal representation of an Addon
export interface Addon {
  type: ChargebeeAddonTypes;
  quantity: number;
}

// Interface for a function that adds or removes an addon
export type AddonHandlerFn = (props: { supplierId: string; quantity?: number }) => Promise<void>;

export interface ChargebeeCoupon {
  id: Coupon['id'];
  discountAmount: Coupon['discount_amount'];
  discountPercentage: Coupon['discount_percentage'];
  currencyCode: Coupon['currency_code'];
  name: Coupon['name'];
  applyOn: Coupon['apply_on'];
  itemConstraints: Coupon['item_constraints'];
  itemConstraintCriteria: Coupon['item_constraint_criteria'];
  availableForSupplierType?: string[];
}

export interface QuoteDiscount extends _quote.discounts_create_sub_items_for_customer_quote_params {
  key: string; // Unique identifier for the discount, can't be called `id` due to issues with API
  apply_on: 'invoice_amount' | 'specific_item_price';
  duration_type: 'one_time' | 'forever' | 'limited_period';
  period_unit?: 'day' | 'week' | 'month' | 'year';
}

// Client-friendly representation of a Chargebee subscription,
// to avoid exposing some private Chargebee fields to the client
export interface UISubscriptionInfo {
  id: Subscription['id'];
  status:
    | 'active'
    | 'future'
    | 'in_trial'
    | 'non_renewing'
    | 'cancelled'
    | 'paused'
    | Subscription['status'];
  billingPeriod: Subscription['billing_period'];
  billingPeriodUnit: 'month' | 'year' | Subscription['billing_period_unit'];
  currentTermStart: Subscription['current_term_start'];
  currentTermEnd: Subscription['current_term_end'];
  nextBillingAt: Subscription['next_billing_at'];
  currencyCode: Subscription['currency_code'];
  items?: {
    itemPriceId: SubscriptionItem['item_price_id'];
    itemType: 'plan' | 'addon' | 'charge' | SubscriptionItem['item_type'];
    quantity: SubscriptionItem['quantity'];
    unitPrice: SubscriptionItem['unit_price'];
    amount: SubscriptionItem['amount'];
  }[];
  dueInvoicesCount: Subscription['due_invoices_count'];
  unpaidInvoicesCount: number;
  hasScheduledChanges: Subscription['has_scheduled_changes'];
  hasAutoCollection: boolean; // customer.auto_collection
  openQuoteId?: string;
  paymentMethod?: {
    type: 'card' | 'direct_debit' | PaymentMethod['type'];
    status: 'valid' | 'expiring' | 'invalid' | PaymentMethod['status'];
  };
}

// Client-friendly representation of a Chargebee addon
// to avoid exposing some private Chargebee fields to the client
export interface UIAddonPrice {
  addonPriceId: string;
  price: number;
  currency: string;
  contractTerm?: string;
  billingPeriod: number;
  billingPeriodUnit: 'day' | 'week' | 'month' | 'year';
}

export interface SummaryItemPrice {
  item: ItemPrice;
  quantity?: number;
  contractTerms?: string;
}
