import { createSelector } from 'reselect';
import { IApplicationState } from 'lib/types';

export const getAchievements = (state: IApplicationState) => state.achievements;

const awards = (state: IApplicationState) => state.achievements.industryAwards;
export const getAwards = createSelector([awards], (awards) => awards);

const media = (state: IApplicationState) => state.achievements.featuredIn;
export const getMedia = createSelector([media], (media) => media);

const accreditations = (state: IApplicationState) => state.achievements.accreditationsAssociations;
export const getAccreditations = createSelector(
  [accreditations],
  (accreditations) => accreditations,
);
