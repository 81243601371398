import {
  checkedAddonAvailabilityAnalytics,
  clickedToViewMarketingPageAnalytics,
  interactedWithSelfServeAnalytics,
  paymentSuccessAnalytics,
  triggeredHubspotPopupAnalytics,
  updatedSupplierPremiumStatusAnalytics,
} from 'lib/premium/analytics/actions';

export enum PremiumAnalyticsActionTypes {
  TRIGGERED_HUBSPOT_POPUP = '[Analytics] Triggered Hubspot popup',
  HUBSPOT_COMPLETED = '[Analytics] Supplier completed register interest form',
  UPDATED_SUPPLIER_PREMIUM_STATUS = '[Analytics] Updated supplier premium status',
  CHECKED_ADDON_AVAILABILITY = '[Analytics] Checked addon availability',
  INTERACTED_WITH_SELF_SERVE = '[Analytics] Interacted with self-serve upgrades',
  PAYMENT_SUCCESS = '[Payment][Analytics] Completed payment',
  CLICKED_TO_VIEW_MARKETING_PAGE = '[Analytics] Clicked to view marketing page',
}

export type TriggeredHubspotPopupAnalyticsAction = ReturnType<
  typeof triggeredHubspotPopupAnalytics
>;

export type IUpdatedSupplierPremiumStatusAnalyticsAction = ReturnType<
  typeof updatedSupplierPremiumStatusAnalytics
>;

export type CheckedAddonAvailabilityAnalyticsAction = ReturnType<
  typeof checkedAddonAvailabilityAnalytics
>;

export type InteractedWithSelfServeAnalyticsAction = ReturnType<
  typeof interactedWithSelfServeAnalytics
>;

export type PaymentSuccessAnalyticsAction = ReturnType<typeof paymentSuccessAnalytics>;

export type ClickedToViewMarketingPageAnalyticsAction = ReturnType<
  typeof clickedToViewMarketingPageAnalytics
>;
