import { FirebaseApp } from 'firebase/app';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { serverTimestamp } from 'firebase/firestore';
import isomorphicFetch from 'isomorphic-fetch';
import { AnalyticsService, analyticsSupplierService } from 'lib/analytics';
import { analyticsErrorService } from 'lib/analytics/analytics-errors-service';
import { env } from 'lib/env';
import validate from './validate';

const firebaseConfig = env.FIREBASE;

const getFirebaseDeps = (firebaseApp: FirebaseApp) => ({
  firebaseApp,
  firebaseAuth: firebase.auth,
  serverTS: serverTimestamp(),
});

export type IFirebaseDeps = ReturnType<typeof getFirebaseDeps>;

const absoluteURL = /^https?:\/\//i;
const ensureServerUrl = (serverUrl: string, input: string) => {
  if (typeof input !== 'string') return input;
  if (absoluteURL.test(input)) return input;
  return serverUrl + input;
};

// Simple wrapper making isomorphic-fetch universal.
const createFetch = (serverUrl: string) => (input: string, init: RequestInit) => {
  input = ensureServerUrl(serverUrl, input);

  return isomorphicFetch(input, init);
};

// Ensure only one Firebase instance.
let firebaseDeps: IFirebaseDeps | null = null;

const createFirebaseDeps = () => {
  if (!firebaseDeps && process.browser) {
    const firebaseApp: FirebaseApp = firebase.initializeApp(firebaseConfig);
    firebase.firestore().settings({
      cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
      experimentalForceLongPolling: true,
    });
    firebaseDeps = getFirebaseDeps(firebaseApp);
  }

  return firebaseDeps as IFirebaseDeps;
};

const fbDeps = createFirebaseDeps();
const analyticsService = new AnalyticsService();
const supplierAnalyticsService = analyticsSupplierService(analyticsService);
const errorsAnalyticsService = analyticsErrorService(analyticsService);

const configureDeps = () => ({
  ...fbDeps,
  fetch: createFetch(''),
  validate,
  firebaseUrl: firebaseConfig.databaseURL,
  now: () => Date.now(),
  bbanalytics: {
    _: analyticsService,
    supplier: supplierAnalyticsService,
    error: errorsAnalyticsService,
  },
});

export type IConfigureDeps = ReturnType<typeof configureDeps>;

export default configureDeps;
