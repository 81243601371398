import { assocPath, values } from 'ramda';
import { ChangeEvent } from 'react';
import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import { DotNotation, ValidationError } from '@bridebook/toolbox';
import { CustomNameValueEvent } from '@bridebook/ui';
import { ISetAchievementFieldArgs } from 'lib/achievements/actions';
import { ContentTranslationsScope } from 'lib/content-translations/types';
import resolveInputType from 'lib/utils/resolve-input-type';
import { IDeps, InputTarget } from '../../types';
import { SupplierActionTypes } from '../action-types';
import { FormSections } from '../types';

export function updateSupplierData(event: CustomNameValueEvent) {
  const {
    target: { name },
  } = event;
  const value = resolveInputType(event.target);
  return {
    type: SupplierActionTypes.UPDATE_SUPPLIER_DATA,
    payload: { name, value },
  };
}

export function updateFieldList({ name, value }: InputTarget) {
  return ({ dispatch }: IDeps) => {
    dispatch(updateSupplierData({ target: { name, value } }));
    return {
      type: SupplierActionTypes.UPDATE_FIELD_LIST,
    };
  };
}

export function updateAwardField({ name, index, section, value }: ISetAchievementFieldArgs) {
  return ({ dispatch, getState }: IDeps) => {
    const { [section]: currentCategoryData } = getState().supplier.supplier;
    const updatedSection = assocPath([index, name], value, currentCategoryData);
    dispatch(updateSupplierData({ target: { name: section, value: values(updatedSection) } }));

    return {
      type: SupplierActionTypes.UPDATE_AWARD_LIST,
    };
  };
}

export const saveSupplier = () => ({
  type: SupplierActionTypes.SAVE_SUPPLIER,
});

export const updateSupplier = (payload: ISupplier) => ({
  type: SupplierActionTypes.UPDATE_SUPPLIER,
  payload,
});

export const updateCheckboxGroup = (event: ChangeEvent<HTMLInputElement>) => {
  const value = resolveInputType(event.target);

  return {
    type: SupplierActionTypes.UPDATE_SUPPLIER_CHECKBOX_GROUP,
    payload: { name: event.target.name, value },
  };
};

export const discardSupplierFormChanges = () => ({
  type: SupplierActionTypes.DISCARD_FORM_CHANGES,
});

// Form section field update
export const updateFormSectionField = (formSection: FormSections, event: CustomNameValueEvent) => {
  const {
    target: { name },
  } = event;
  const value = resolveInputType(event.target);
  return {
    type: SupplierActionTypes.UPDATE_FORM_SECTION_FIELD,
    payload: { name, value, formSection },
  };
};

export const updateFormSectionCheckboxGroup = (
  formSection: FormSections,
  event: ChangeEvent<HTMLInputElement>,
) => {
  const value = resolveInputType(event.target);
  return {
    type: SupplierActionTypes.UPDATE_FORM_SECTION_CHECKBOX_GROUP,
    payload: { name: event.target.name, value, formSection },
  };
};

// Form sections save
export const saveFormSection = <P extends object>(
  formSection: FormSections,
  scope?: ContentTranslationsScope,
  translationPaths?: DotNotation<P>[],
) => ({
  type: SupplierActionTypes.SAVE_FORM_SECTION,
  payload: { formSection, scope, translationPaths },
});

export const saveFormSectionSuccess = (formSection: FormSections) => ({
  type: SupplierActionTypes.SAVE_FORM_SECTION_SUCCESS,
  payload: formSection,
});

export const saveFormError = (error: ValidationError) => ({
  type: SupplierActionTypes.SAVE_FORM_ERROR,
  payload: error,
});
