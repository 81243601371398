import { IReport } from '@bridebook/models/source/models/Suppliers/Reports.types';
import gazetteer, { Gazetteer } from '@bridebook/toolbox/src/gazetteer';
import { getPreviousPath, getSupplierLocale } from 'lib/app/selectors';
import {
  IClickedToGenerateROIReportAnalytics,
  ROIAnalyticsActions,
} from 'lib/roi/analytics/actions';
import { IReportBasicData } from 'lib/roi/types';
import { mapROIReportToAnalyticsPayload } from 'lib/roi/utils/map-ROI-report-to-analytics-payload';
import { getSupplierId, getSupplierTier, getSupplierType } from 'lib/supplier/selectors';
import { getSupplierCountryCode, getSupplierName } from 'lib/supplier/selectors/contact';
import { Action, IApplicationState, State } from 'lib/types';
import CmsAnalyticsContext from 'lib/utils/cms-analytics-context';

const commonAnalyticsProperties = (state: IApplicationState) => () => {
  const supplierCountry = state.supplier.supplier?.l10n.country;
  const market = supplierCountry ? gazetteer.getMarketByCountry(supplierCountry) : null;
  const currency = market?.currency;
  const countryCode = getSupplierCountryCode(state);
  const countryName = countryCode ? Gazetteer.getCountryName(countryCode) : undefined;

  return {
    category: 'CMS',
    countryCode,
    countryName,
    currency,
    previousPath: getPreviousPath(state),
    locale: getSupplierLocale(state),
    supplierCategory: getSupplierType(state),
    supplierId: getSupplierId(state),
    supplierName: getSupplierName(state),
    supplierTier: getSupplierTier(state),
  };
};

export default function ROIAnalytics(
  action: Action,
  bridebookAnalytics: CmsAnalyticsContext,
  getState: () => State,
) {
  const { track } = bridebookAnalytics.getMethods('CMS');
  const state = getState();

  switch (action.type) {
    case ROIAnalyticsActions.ClickedToSetUpROICalculator: {
      track({
        event: action.type,
        ...commonAnalyticsProperties(state),
        lastTierUpdateDate: action.payload,
      });
      break;
    }
    case ROIAnalyticsActions.ClickedToGenerateROIReport: {
      const payload = action.payload as IClickedToGenerateROIReportAnalytics;
      track({
        event: action.type,
        ...commonAnalyticsProperties(state),
        ...payload,
      });
      break;
    }
    case ROIAnalyticsActions.GeneratedROIReport: {
      const payload = action.payload as IReport;
      track({
        event: action.type,
        ...commonAnalyticsProperties(state),
        ...mapROIReportToAnalyticsPayload(payload),
      });
      break;
    }
    case ROIAnalyticsActions.DownloadedROIReport:
    case ROIAnalyticsActions.EditedROIReport:
    case ROIAnalyticsActions.RevealedLatestROIReport:
    case ROIAnalyticsActions.ViewedROIReport: {
      const payload = action.payload as IReportBasicData;
      track({
        event: action.type,
        ...commonAnalyticsProperties(state),
        ...payload,
      });
      break;
    }

    default:
      break;
  }
}
