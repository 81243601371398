import { createSelector } from 'reselect';
import {
  countriesWithHubspot,
  countriesWithSupplierPro,
} from '@bridebook/toolbox/src/i18n/features';
import { getSupplierCountryCode } from 'lib/app/selectors';
import { isPaidTier } from 'lib/premium/utils/tiers';
import { getIsVenue, getSupplierTier, getSupplierType } from 'lib/supplier/selectors';
import { getUrlHelperLandings } from 'lib/url-helper-landings';

/**
 * Returns true if supplier can see SupplierPro features (locked or not)
 */
export const getIsSupplierProAvailable = createSelector(
  [getIsVenue, getSupplierCountryCode],
  (isVenue, countryCode) =>
    // Only UK && DE suppliers supported
    countriesWithSupplierPro.includes(countryCode) &&
    // Check if supported supplier type
    !isVenue,
);

/**
 * Returns true if any of the premium features from VenuePro or SupplierPro (locked or not) should be visible
 */
export const getIsPremiumPromoVisible = createSelector(
  [getIsVenue, getIsSupplierProAvailable],
  (isVenue, isSupplierProAvailable) => isVenue || isSupplierProAvailable,
);

/**
 * Returns link to the pricing page based on supplier type and locale
 */
export const getPricingPageUrl = createSelector(
  [getSupplierType, getSupplierCountryCode],
  (supplierType, countryCode): string | undefined => {
    if (!supplierType || supplierType === 'group') {
      return;
    }

    if (supplierType === 'venue') {
      return getUrlHelperLandings(countryCode).pricing.venue;
    }

    return getUrlHelperLandings(countryCode).pricing.suppliers?.[supplierType];
  },
);

export const getIsHubspotAvailable = createSelector(
  [getIsVenue, getSupplierCountryCode, getSupplierTier, getPricingPageUrl],
  (isVenue, countryCode, supplierTier, pricingPageUrl) => {
    // Return false if not a supported country
    if (!countriesWithHubspot.includes(countryCode)) return false;

    // Return false if already paying - should be handled by specific Account Executive instead
    if (isPaidTier(supplierTier)) return false;

    // Return true if venue - they should set up a call on Hubspot
    if (isVenue) return true;

    // Show Hubspot only if pricing page for the supplier type doesn't exist (so there is no self-serve)
    return !pricingPageUrl;
  },
);
