import { supplierPropertiesGeneric } from 'lib/analytics-utils';
import serverSideTrack from 'lib/analytics-utils/server-side-track';
import { supplierListingPropertiesGeneric } from 'lib/analytics-utils/supplierListingPropertiesGeneric';
import {
  CheckedAddonAvailabilityAnalyticsAction,
  ClickedToViewMarketingPageAnalyticsAction,
  IUpdatedSupplierPremiumStatusAnalyticsAction,
  InteractedWithSelfServeAnalyticsAction,
  PaymentSuccessAnalyticsAction,
  PremiumAnalyticsActionTypes,
  TriggeredHubspotPopupAnalyticsAction,
} from 'lib/premium/analytics/action-types';
import { Action, State } from 'lib/types';
import CmsAnalyticsContext from 'lib/utils/cms-analytics-context';

export default function premiumAnalytics(
  action: Action,
  bridebookAnalytics: CmsAnalyticsContext,
  getState: () => State,
) {
  const { track } = bridebookAnalytics.getMethods('CMS');

  switch (action.type) {
    case PremiumAnalyticsActionTypes.TRIGGERED_HUBSPOT_POPUP: {
      const {
        payload: { touchPointLocation },
      } = action as TriggeredHubspotPopupAnalyticsAction;
      track({
        event: 'Supplier clicked to trigger call schedule pop up',
        schedulePopupLocation: touchPointLocation,
        ...supplierPropertiesGeneric(getState()),
      });
      break;
    }

    case PremiumAnalyticsActionTypes.HUBSPOT_COMPLETED: {
      const state = getState();
      const supplier = state.supplier.supplier;
      if (!supplier) break;
      serverSideTrack({
        event: 'Supplier completed register interest form',
        state,
        specificEventProps: {
          category: 'VenuePro',
          location: state.app.pathname,
          ...supplierPropertiesGeneric(state),
          ...supplierListingPropertiesGeneric(state),
        },
        // eslint-disable-next-line no-console
      }).catch((err) => console.error(err));
      break;
    }

    case PremiumAnalyticsActionTypes.UPDATED_SUPPLIER_PREMIUM_STATUS: {
      const {
        payload: { premiumUpdateLocation, premium },
      } = action as IUpdatedSupplierPremiumStatusAnalyticsAction;
      const state = getState();

      const {
        admin: { supplierAdminObject },
      } = state;

      const tierUpdatedAt = Date.now();
      const tierCreatedAt = supplierAdminObject?.premium?.createdAt.toMillis() || tierUpdatedAt;

      const premiumObject = {
        premiumActive: premium.active,
        tier: premium.tier,
        tierCreatedAt,
        tierUpdatedAt,
      };

      serverSideTrack({
        event: 'Updated supplier premium status',
        state,
        identifyProps: {
          ...supplierPropertiesGeneric(state),
          ...premiumObject,
        },
        specificEventProps: {
          category: 'VenuePro',
          location: state.app.pathname,
          premiumUpdateLocation,
          ...premiumObject,
          ...supplierPropertiesGeneric(state),
        },
        // eslint-disable-next-line no-console
      }).catch((err) => console.error(err));
      break;
    }

    case PremiumAnalyticsActionTypes.CHECKED_ADDON_AVAILABILITY: {
      const { payload } = action as CheckedAddonAvailabilityAnalyticsAction;
      const state = getState();
      track({
        event: 'Supplier checked addon availability',
        ...payload,
        ...supplierPropertiesGeneric(state),
      });
      break;
    }

    case PremiumAnalyticsActionTypes.INTERACTED_WITH_SELF_SERVE: {
      const { payload } = action as InteractedWithSelfServeAnalyticsAction;
      const state = getState();
      track({
        event: 'Supplier interacted with self-serve upgrades',
        ...payload,
        ...supplierPropertiesGeneric(state),
      });
      break;
    }

    case PremiumAnalyticsActionTypes.CLICKED_TO_VIEW_MARKETING_PAGE: {
      const { payload } = action as ClickedToViewMarketingPageAnalyticsAction;
      const state = getState();
      track({
        event: 'Supplier clicked to view marketing page',
        ...supplierPropertiesGeneric(state),
        ...supplierListingPropertiesGeneric(state),
        ...payload,
      });
      break;
    }

    case PremiumAnalyticsActionTypes.PAYMENT_SUCCESS: {
      const { payload } = action as PaymentSuccessAnalyticsAction;
      track({
        event: 'Supplier completed purchase',
        ...payload,
      });
      break;
    }

    default: {
      break;
    }
  }
}
