import { WithFieldValue } from 'firebase/firestore';
import { ISupplierAdmin } from '@bridebook/models/source/models/Suppliers/Admin.types';
import { CheckoutLocations } from 'pages/checkout/success';
import { AddonAvailabilityStatus } from 'lib/api/chargebee/addons/utils/availability-status';
import { ChargebeeAddonTypes } from 'lib/api/chargebee/types';
import { PremiumAnalyticsActionTypes } from 'lib/premium/analytics/action-types';
import { mapChargebeeAddonTypeToAnalytics } from 'lib/premium/analytics/utils';
import { VenueProContactFormCompletedStorage } from 'lib/storage-manager/basic/venuepro-contact-form-storage';
import { TTouchPointLocation } from 'lib/types';

export const triggeredHubspotPopupAnalytics = (payload: {
  touchPointLocation?: TTouchPointLocation | 'phoneNumber' | 'settings' | 'enquiryManager';
}) => ({
  type: PremiumAnalyticsActionTypes.TRIGGERED_HUBSPOT_POPUP,
  payload,
});

export const hubspotCompletedAnalytics = () => {
  VenueProContactFormCompletedStorage.set(true);
  return {
    type: PremiumAnalyticsActionTypes.HUBSPOT_COMPLETED,
  };
};

export const updatedSupplierPremiumStatusAnalytics = ({
  premiumUpdateLocation,
  premium,
}: {
  premiumUpdateLocation: 'admin panel' | 'trialExpirationPopup' | 'checkout';
  premium: WithFieldValue<ISupplierAdmin['premium']>;
}) => ({
  type: PremiumAnalyticsActionTypes.UPDATED_SUPPLIER_PREMIUM_STATUS,
  payload: { premiumUpdateLocation, premium },
});

export const checkedAddonAvailabilityAnalytics = (payload: {
  selectedAddonAvailability: boolean;
  selectedAddonAvailabilityStatus?: AddonAvailabilityStatus;
  selectedAddonType?: ChargebeeAddonTypes;
  selectedCounty?: string;
}) => ({
  type: PremiumAnalyticsActionTypes.CHECKED_ADDON_AVAILABILITY,
  payload: {
    ...payload,
    ...(payload.selectedAddonType && {
      selectedAddonType: mapChargebeeAddonTypeToAnalytics[payload.selectedAddonType],
    }),
    interactionLocation: 'upgradesUpsellPage',
  },
});

export const interactedWithSelfServeAnalytics = (payload: {
  interactionType:
    | 'upgradeTier'
    | 'requestMeetingPrePurchase'
    | 'requestMeetingPostPurchase'
    | 'joinWaitlist'
    | 'buyNow';
  selectedAddonType?: ChargebeeAddonTypes;
  selectedCounty?: string;
}) => ({
  type: PremiumAnalyticsActionTypes.INTERACTED_WITH_SELF_SERVE,
  payload: {
    ...payload,
    ...(payload.selectedAddonType && {
      selectedAddonType: mapChargebeeAddonTypeToAnalytics[payload.selectedAddonType],
    }),
    interactionLocation: 'upgradesUpsellPage',
    selectedProductType: 'addons',
  },
});

export const clickedToViewMarketingPageAnalytics = (payload: {
  touchPointLocation?: TTouchPointLocation;
  selectedProductType?: 'addons';
  selectedAddonType?: ChargebeeAddonTypes;
}) => ({
  type: PremiumAnalyticsActionTypes.CLICKED_TO_VIEW_MARKETING_PAGE,
  payload: {
    ...payload,
    ...(payload.selectedAddonType && {
      selectedAddonType: mapChargebeeAddonTypeToAnalytics[payload.selectedAddonType],
    }),
  },
});

export const paymentSuccessAnalytics = (payload: {
  supplierId: string;
  supplierType?: string;
  supplierName?: string;
  selectedPlan?: string; // Chargebee plan price id
  selectedAddons?: string[]; // Chargebee addon price ids
  checkoutLocation: CheckoutLocations;
  upgrade: boolean;
}) => ({
  type: PremiumAnalyticsActionTypes.PAYMENT_SUCCESS,
  payload,
});
