import type { ISupplierWeddingStats } from '@bridebook/toolbox/src/supplier-wedding-manager';

const numStatuses: ISupplierWeddingStats = {
  booked: 0,
  callOrMeetingHeld: 0,
  dateOnHold: 0,
  filtered: 0,
  hasAssignee: 0,
  hasNotes: 0,
  hasProgressUpdates: 0,
  inDiscussion: 0,
  inprogress: 0,
  lastMonth: 0,
  total: 0,
  unread: 0,
};

export default numStatuses;
